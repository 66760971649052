import { SPACES, CHANCE_POSITIONS } from "./constants";
import { debounce } from "lodash";

export const handleSpaceClickImpl = (
  clickedPosition,
  isSelectingStart,
  setStartingPosition,
  setCurrentPosition,
  setPositions,
  currentMultiplier,
  setIsSelectingStart,
  isSelectingTargets,
  setTargetPositions,
  positions,
  startingPosition,
  rolls,
  setRolls,
  setUsedMultipliers,
  setShowChancePrompt,
  currentPosition,
  isAdjustingTargets,
  isCalculated
) => {
  setShowChancePrompt(false);

  if (isSelectingStart) {
    setStartingPosition(clickedPosition);
    setCurrentPosition(clickedPosition);
    setPositions((prev) => ({
      ...prev,
      [currentMultiplier]: clickedPosition,
    }));
    setIsSelectingStart(false);
  } else if (isSelectingTargets || isAdjustingTargets) {
    setTargetPositions((prev) =>
      prev.includes(clickedPosition)
        ? prev.filter((pos) => pos !== clickedPosition)
        : [...prev, clickedPosition]
    );
  } else if (!isCalculated) {
    const fromPosition = positions[currentMultiplier] ?? startingPosition;
    const rollValue =
      (clickedPosition - fromPosition + SPACES) % SPACES || SPACES;

    if (rollValue >= 2 && rollValue <= 12) {
      setRolls((prevRolls) => {
        const maxRollForMultiplier = prevRolls.reduce((max, group) => {
          const rollForMultiplier = group.find(
            (roll) => roll.multiplier === currentMultiplier
          );
          return rollForMultiplier
            ? Math.max(max, rollForMultiplier.roll)
            : max;
        }, 0);

        const newRollNumber = maxRollForMultiplier + 1;

        const newRoll = {
          roll: newRollNumber,
          multiplier: currentMultiplier,
          rollValue,
          from: currentPosition,
          to: clickedPosition,
        };

        let updatedRolls;
        if (prevRolls.length === 0) {
          updatedRolls = [[newRoll]];
        } else {
          updatedRolls = [...prevRolls];
          if (updatedRolls[newRollNumber - 1]) {
            updatedRolls[newRollNumber - 1].push(newRoll);
          } else {
            updatedRolls.push([newRoll]);
          }
        }

        return updatedRolls.map((group) =>
          group.sort((a, b) => a.multiplier - b.multiplier)
        );
      });

      setUsedMultipliers((prev) => new Set(prev).add(currentMultiplier));
      setCurrentPosition(clickedPosition);
      setPositions((prev) => ({
        ...prev,
        [currentMultiplier]: clickedPosition,
      }));

      if (CHANCE_POSITIONS.includes(clickedPosition)) {
        setShowChancePrompt(true);
      }
    } else {
      throw new Error("Invalid move. Rolls must be between 2 and 12.");
    }
  }
};

export const handleSpaceClick = debounce(handleSpaceClickImpl, 300);

export const handleMultiplierChange = (
  multiplier,
  setCurrentMultiplier,
  setCurrentPosition,
  positions,
  startingPosition
) => {
  setCurrentMultiplier(multiplier);
  setCurrentPosition(positions[multiplier] ?? startingPosition);
};

export const handleRollClear = (
  setRolls,
  setPositions,
  setStartingPosition,
  setCurrentPosition,
  setUsedMultipliers,
  setCurrentMultiplier,
  setSelectedCalculationMultiplier,
  setCalculatedPath,
  setTargetPositions,
  setShowChancePrompt,
  setIsSelectingStart,
  setActiveMultipliers,
  setInactiveMultipliers,
  setIsCalculated,
  setCurrentStepIndex,
  setImportStatus,
  setSelectedToken
) => {
  // Reset rolls and positions
  setRolls([]);
  setPositions({});

  // Reset starting and current positions
  setStartingPosition(null);
  setCurrentPosition(null);

  // Reset multipliers
  setUsedMultipliers(new Set([1]));
  setCurrentMultiplier(1);
  setSelectedCalculationMultiplier(1);
  setActiveMultipliers([]); // Set to default active multipliers
  setInactiveMultipliers([]); // Set to default inactive multipliers

  // Reset calculation-related states
  setCalculatedPath([]);
  setTargetPositions([]);
  setIsCalculated(false);
  setCurrentStepIndex(-1);

  // Reset UI states
  setShowChancePrompt(false);
  setIsSelectingStart(true);

  // Reset import status
  setImportStatus("");

  // Reset selected token to default (if applicable)
  setSelectedToken("tokens/Aeroplane.png"); // Adjust this to your default token
};

export const calculateTotalRolls = (rolls) => {
  return rolls.flat().reduce((total, roll) => total + roll.rollValue, 0);
};

export const getLastRollForMultiplier = (rolls, multiplier) => {
  const flatRolls = rolls.flat();
  return flatRolls.filter((roll) => roll.multiplier === multiplier).pop();
};

export const isValidMove = (from, to) => {
  const distance = (to - from + SPACES) % SPACES;
  return distance >= 2 && distance <= 12;
};
